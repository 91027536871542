import React, { useState, useRef, useEffect, useContext } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/browser';

import { get, post } from '../utils/request';
import { sentryIds } from '../utils/errors';

import { Button, Input, LargeText } from '../components';

import { EmailSent, Email } from '../assets/images/svg';
import SurveyContext from '../contexts/SurveyContext';

let allowedHosts;

const SuccessWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	justify-self: center;
	flex: 1 1 auto;

	svg {
		width: 10rem;
		height: auto;
		fill: #fff;
	}
`;

const InputWrapper = styled.div`
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	justify-content: center;
	width: 100%;

	svg * {
		fill: ${({ error }) => (error ? '#E90118' : '#01B3FB')};
	}
`;

const mailFormat = /^\w+([.-]?\w+)*@tennet\.eu+$/;

const REGISTRATION_STATE = {
	MAIL: 'mail',
	SUCCESS: 'success',
	ERROR: 'error',
};

const MAIL_VALIDITY = {
	VALID: true,
	INVALID: false,
};

export default function SurveyRegistration() {
	const { t } = useTranslation();
	const { push } = useHistory();
	const inputRef = useRef();

	const { survey } = useContext(SurveyContext);
	const { surveyId, locale } = useParams();

	const [mailValidity, setMailValidity] = useState(MAIL_VALIDITY.VALID);
	const [message, setMessage] = useState(REGISTRATION_STATE.MAIL); // Success or error message
	const [errorCode, setErrorCode] = useState(null);

	useEffect(() => {
		if (allowedHosts === undefined) {
			get(`/${locale}/allowed-hosts.json`, {}, true).then((json) => {
				if (json.success) {
					allowedHosts = json.data;
				}
			});
		}
	}, [locale]);

	if (survey.end !== null) {
		const now = Date.parse(new Date());
		const end = Date.parse(survey.end);
		if (end < now) {
			return <Redirect to={`/${locale}/surveys/${surveyId}`} />;
		}
	}

	const handleSubmit = async () => {
		if (!inputRef.current) {
			// Stop if inputRef is not defined
			return;
		}
		// local validation of email
		const mail = inputRef.current.value.toLowerCase();
		if (allowedHosts !== undefined) {
			const emailHost = mail.split('@', 2)[1];
			if (allowedHosts.indexOf(emailHost) < 0) {
				// E-Mail is not in the list of allowed hosts.
				setMessage(REGISTRATION_STATE.ERROR);
				setMailValidity(MAIL_VALIDITY.INVALID);
				setErrorCode(1587651027669);
				return;
			}
		} else if (process.env.NODE_ENV === 'development' && !mail.match(mailFormat)) {
			// invalid email
			setMessage(REGISTRATION_STATE.ERROR);
			setMailValidity(MAIL_VALIDITY.INVALID);
			setErrorCode(1587650886322);
			return;
		}

		post(`/${locale}/surveys/${surveyId}/add-participant.json`, { mail })
			.then((response) => {
				if (response.success) {
					setMessage(REGISTRATION_STATE.SUCCESS);
					setErrorCode(null);
				} else {
					setMessage(REGISTRATION_STATE.ERROR);
					setMailValidity(MAIL_VALIDITY.INVALID);
					setErrorCode(response.error.code);

					// Capture exception for defined error codes
					if (sentryIds.indexOf(response.error.code) > -1) {
						Sentry.withScope((scope) => {
							scope.setExtras(response.error);

							// Capture exception
							Sentry.captureException(new Error(response.error.message));
						});
					}
				}
			})
			.catch(() => {
				push(`/${locale}/error/`);
			});
	};

	if (message === REGISTRATION_STATE.SUCCESS) {
		return (
			<SuccessWrapper>
				<EmailSent />
				<LargeText color="#fff">{t('surveys.registration.success')}</LargeText>
			</SuccessWrapper>
		);
	}

	return (
		<>
			<InputWrapper error={!mailValidity}>
				<Input
					type="email"
					placeholder="name@tennet.eu"
					inputRef={inputRef}
					error={!mailValidity}
					label={t('surveys.registration.mail')}
					icon={<Email />}
					message={mailValidity ? '\u200D' : t(`api.${errorCode}`)}
					onEnter={handleSubmit}
				/>
			</InputWrapper>

			<Button type="button" onClick={handleSubmit}>
				{t('surveys.registration.send')}
			</Button>
		</>
	);
}
