import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { questionPropTypes } from '../utils/prop-types';
import { Options, HiddenInput, OptionWrapper, Option } from './StyledQuestionComponents';
import { LargeText, Text } from './Text';
import { Done as CheckMarkPlaceholder } from '../assets/images/icons';

const checkedStyles = css`
	path {
		visibility: visible;
		opacity: 1;
	}
`;

const iconStyles = css`
	path {
		fill: #fff;
		visibility: hidden;
		opacity: 0;
		transition: opacity 250ms ease-in-out;
	}
`;

export default function MultiSelectQuestion({ question, onChange }) {
	const [selection, setSelection] = useState([]);

	return (
		<>
			<LargeText color="#fff" center margin={[1, 0]}>
				{question.question}
			</LargeText>

			<Options>
				{question.options.map(({ value }, index) => (
					<OptionWrapper key={`${question.question}-${value}`}>
						<HiddenInput
							id={`multi-select-question-${index}`}
							type="checkbox"
							value="value"
							checkedStyles={checkedStyles}
							onChange={() => {
								const update = selection.includes(value)
									? selection.filter((v) => v !== value)
									: [...selection, value];
								setSelection(update);
								onChange(update);
							}}
						/>
						<Option htmlFor={`multi-select-question-${index}`} iconStyles={iconStyles}>
							<CheckMarkPlaceholder />
							<Text center>{value}</Text>
						</Option>
					</OptionWrapper>
				))}
			</Options>
		</>
	);
}

MultiSelectQuestion.propTypes = {
	question: questionPropTypes,
	onChange: PropTypes.func,
};
