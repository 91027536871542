import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { COLORS } from '../utils/config';
import easeFn from '../utils/ease';

const Wrapper = styled.div`
	width: 100%;
	position: relative;
	margin: 1rem 0;

	opacity: 0;

	transition: opacity ease-in-out ${({ animationDuration }) => animationDuration}ms;

	&.active {
		opacity: 1;
	}
`;

const Legend = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-top: 0.3rem;
	line-height: 1;
	font-size: 1rem;
`;

const Percentage = styled.span`
	font-family: 'Helvetica Now Display W01 Bold';
	font-size: 1.25rem;
	line-height: 1;
	color: #485156;
	@media (min-width: 600px) {
		font-size: 2rem;
		line-height: 1;
	}
	@media (min-width: 800px) {
		font-size: 3rem;
	}
`;

const Option = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	line-height: 1.25;

	@media (min-width: 550px) {
		padding: 0 5%;
	}
	:first-of-type {
		margin-left: ${({ margin }) => margin}px;
	}
	:last-of-type {
		margin-right: ${({ margin }) => margin}px;
		text-align: right;
	}
`;

export default class TachoChart extends Component {
	constructor(props) {
		super(props);

		this.canvasRef = createRef();

		this.state = {
			isActive: false,
		};

		this.ctx = null;

		this.raf = null;
		this.animationStart = 0;
		this.redrawTimeout = null;
	}

	componentDidMount() {
		this.ctx = this.canvasRef.current.getContext('2d');

		this.calculateTacho();

		if (this.props.isActive) this.startAnimation();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.width !== this.props.width) {
			clearTimeout(this.redrawTimeout);
			this.calculateTacho();

			if (this.props.isActive && this.state.isActive) {
				this.redrawTimeout = setTimeout(() => this.startAnimation(), 500);
				return;
			}
		}
		if (!prevProps.isActive && this.props.isActive) {
			this.startAnimation();
		} else if (prevProps.isActive && !this.props.isActive) {
			this.setState({ isActive: false });
		}
	}

	calculateTacho() {
		const { offset, scale, width, ratio } = this.props;

		const height = width / ratio;

		this.canvasRef.current.setAttribute('width', width);
		this.canvasRef.current.setAttribute('height', height);

		const offsetX = offset[0] * width;
		const offsetY = offset[1] * height;

		const lineWidth = (Math.min(width, height) / 3) * scale;
		const radius = (Math.min(width / 2, height) - lineWidth / 2) * scale;

		const x = width / 2 + offsetX;
		const y = height + offsetY;

		this.setState({
			lineWidth,
			radius,
			x,
			y,
			width,
			height,
		});
	}

	startAnimation() {
		setTimeout(() => {
			this.animationStart = Date.now();
			this.animate();

			this.setState({ isActive: true });
		}, 250);
	}

	animate() {
		const { lineWidth, radius, x, y, width, height } = this.state;
		const { options, animationDuration } = this.props;

		let progress = (Date.now() - this.animationStart) / animationDuration;

		if (progress >= 1) progress = 1;

		// clear the canvas
		this.ctx.clearRect(0, 0, width, height);

		// draw the bottom line
		this.ctx.fillStyle = 'rgba(0,0,0,0.2)';
		this.ctx.fillRect(0, height, width, height * -0.05);

		const ease = easeFn(progress);

		this.ctx.lineWidth = lineWidth;

		// draw the 'first option' (right side) (or a simple half arc)
		this.drawCircle({
			angle: Math.PI * ease,
			color: '#33A92F',
			lineWidth,
			radius,
			x,
			y,
		});

		// draw the second option (left side) on top of the first option
		this.drawCircle({
			angle: Math.PI * (options[0].percentage / 100) * ease,
			color: '#003584',
			lineWidth,
			radius,
			x,
			y,
		});

		// draw the 'darkenLine' thing
		const darkenLineWidth = lineWidth * 0.15;
		const darkenLineRadius = radius - lineWidth / 2 + darkenLineWidth / 2;

		this.drawCircle({
			angle: Math.PI * ease,
			color: 'rgba(0,0,0,0.2)',
			lineWidth: darkenLineWidth,
			radius: darkenLineRadius,
			x,
			y,
		});

		if (progress < 1) this.raf = window.requestAnimationFrame(() => this.animate(), 100);
	}

	drawCircle({ angle, color, lineWidth, radius, x, y }) {
		this.ctx.strokeStyle = color;
		this.ctx.lineWidth = lineWidth;
		this.ctx.beginPath();
		this.ctx.arc(x, y, radius, Math.PI, Math.PI + angle);
		this.ctx.stroke();
	}

	render() {
		const { options, width, ratio, offset, scale, animationDuration } = this.props;
		const { isActive } = this.state;

		const height = width / ratio;
		const lineWidth = (Math.min(width, height) / 3) * scale;
		const optionWidth = (Math.min(width, height) / 3) * scale;
		const radius = (Math.min(width / 2, height) - lineWidth / 2) * scale;
		const margin = width / 2 - radius - lineWidth / 2 + offset[0];

		return (
			<Wrapper className={isActive ? 'active' : ''} animationDuration={animationDuration}>
				<canvas ref={this.canvasRef} />
				<Legend>
					{options.map(({ value, percentage }, i) => (
						<Option key={value} width={optionWidth} margin={margin} color={COLORS[i]}>
							<Percentage>{percentage}%</Percentage>
							{value}
						</Option>
					))}
				</Legend>
			</Wrapper>
		);
	}
}

TachoChart.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			percentage: PropTypes.number,
		}),
	),
	scale: PropTypes.number,
	offset: PropTypes.arrayOf(PropTypes.number),
	ratio: PropTypes.number,
	width: PropTypes.number,
	isActive: PropTypes.bool,
	animationDuration: PropTypes.number,
};

TachoChart.defaultProps = {
	scale: 0.9,
	offset: [0, 0],
	ratio: 16 / 10,
	width: 100,
	animationDuration: 500,
};
