import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { Global } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import {
	BrowserRouter as Router,
	useLocation,
	useHistory,
	Switch,
	Route,
	Redirect,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import qs from 'qs';

import SurveyContext, { SurveyContextProvider } from './contexts/SurveyContext';

import AppHeader from './components/AppHeader';
import { SmallText } from './components/Text';

import { LANGUAGES } from './utils/config';
import i18n, { lng } from './utils/i18n';
import { get } from './utils/request';

import Routes from './routes';
import styles from './styles';

const BACKGROUND = {
	DEFAULT: '#f0f0f0',
	PRIMARY: '#01b3fb',
};

const Main = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	margin: 0 auto;
	max-width: 800px;
	width: 100%;
	padding: 1rem;
	align-items: center;
	justify-content: center;
`;

const AppContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: ${({ background }) => background};

	a {
		text-decoration: none;
	}
`;

const Footer = styled.div`
	flex: 0 0 2em;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ primary }) => (primary ? '#485156' : '#fff')};
	a {
		margin: 0 0.4rem;
		color: inherit;
	}
`;

function AppContent() {
	const { t } = useTranslation();
	const { push } = useHistory();
	const location = useLocation();
	const { surveyId } = (
		useRouteMatch(`/:locale(${LANGUAGES.join('|')})/surveys/:surveyId(\\d+)`) || { params: {} }
	).params;
	const isSurveyRoute = useRouteMatch(
		`/:locale(${LANGUAGES.join('|')})/surveys/:surveyId(\\d+)/*`,
	);
	const { locale } = useParams();

	const [background, setBackground] = useState(BACKGROUND.DEFAULT);
	const [initialized, setInitialization] = useState(false);
	const { setSurvey, setQuestions, setHash, setToken, debugMode, cacheCount } = useContext(
		SurveyContext,
	);

	useEffect(() => {
		if (locale) {
			i18n.changeLanguage(locale);
			let url;
			if (debugMode === true && process.env.NODE_ENV === 'development') {
				url = '/debug.php';
			} else {
				url = surveyId ? `/${locale}/surveys/${surveyId}.json` : `/${locale}.json`;
			}

			if (window.location.search && 'URLSearchParams' in window) {
				const params = new URLSearchParams(window.location.search.substr(1));
				if (params.get('debug')) {
					url += `?debug=${params.get('debug')}`;
				}
			}

			get(url, {}, true)
				.then(({ data, success, error }) => {
					if (success && data) {
						const { questions, ...rest } = data;

						setSurvey(rest);
						setQuestions(questions);
					} else {
						setSurvey(null);
						setQuestions([]);

						if (!success && error) push(`/${locale}/error/${error.code}`);
					}

					const params = qs.parse(location.search.replace(/^\?/, ''));

					if (typeof params.token !== 'undefined') setToken(params.token);
					if (typeof params.hash !== 'undefined') setHash(params.hash);

					setInitialization(true);
				})
				.catch(() => {
					push(`/${locale}/error/1588141963941`);
					setInitialization(true);
				});
		}
	}, [
		locale,
		surveyId,
		location.search,
		setHash,
		setQuestions,
		setSurvey,
		setToken,
		cacheCount,
		debugMode,
		push,
	]);

	useEffect(() => {
		if (isSurveyRoute) setBackground(BACKGROUND.PRIMARY);
		else setBackground(BACKGROUND.DEFAULT);
	}, [isSurveyRoute]);

	return (
		<AppContainer background={background}>
			<AppHeader />
			<Main>{initialized ? <Routes /> : <p>{t('navigation.initialization')}</p>}</Main>

			<Footer>
				<SmallText
					weight="Rg"
					color={isSurveyRoute ? '#fff' : '#485156'}
					onClick={() => push(`/${locale}/info/`)}
				>
					<a href="https://www.tennet.eu/de/impressum/">{t('navigation.imprint')}</a>|
					<a
						href={
							locale === 'de'
								? 'https://www.tennet.eu/de/datenschutz/'
								: 'https://www.tennet.eu/privacy-and-data-protection/'
						}
					>
						{t('navigation.privacy')}
					</a>
				</SmallText>
			</Footer>
		</AppContainer>
	);
}

function LangRedirect() {
	const location = useLocation();

	// check for missing trailing /
	const localeMatch = new RegExp(`^\\/(${LANGUAGES.join('|')})\\/?$`);
	const match = location.pathname.match(localeMatch);
	if (match && match[1]) return <Redirect to={`/${match[1]}/`} />;

	// else redirect to lng
	return <Redirect to={`/${lng}/`} />;
}

function App() {
	const localePart = `:locale(${LANGUAGES.join('|')})`;

	return (
		<>
			<Global styles={styles} />
			<SurveyContextProvider>
				<Router>
					<Switch>
						<Route path={`/${localePart}/*`} component={AppContent} />
						<LangRedirect />
					</Switch>
				</Router>
			</SurveyContextProvider>
		</>
	);
}

export default App;
