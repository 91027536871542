import React from 'react';

import { Switch, Route, Redirect, useParams } from 'react-router-dom';

import Info from './info';
import Home from './home';
import SurveyEnd from './survey-end';
import SurveyQuestion from './survey-question';
import SurveyStart from './survey-start';
import SurveyRegistration from './survey-registration';
import Error from './error';

import { LANGUAGES } from '../utils/config';

export default function Routes() {
	const { locale } = useParams();

	const localePart = `:locale(${LANGUAGES.join('|')})`;

	return (
		<Switch>
			<Route exact path={`/${localePart}/info/`}>
				<Info />
			</Route>

			<Route exact path={`/${localePart}/surveys/:surveyId/registration`}>
				<SurveyRegistration />
			</Route>

			<Route exact path={`/${localePart}/surveys/:surveyId/start`}>
				<SurveyStart />
			</Route>

			<Route exact path={`/${localePart}/surveys/:surveyId/questions`}>
				<SurveyQuestion />
			</Route>

			<Route exact path={`/${localePart}/surveys/:surveyId([0-9]+)/end`}>
				<SurveyEnd />
			</Route>

			<Route exact path={`/${localePart}/surveys/:surveyId([0-9]+)`}>
				<Home />
			</Route>

			<Route exact path={`/${localePart}/`}>
				<Home />
			</Route>

			<Route path={`/${localePart}/error/:error?`}>
				<Error />
			</Route>

			<Route path="*">
				<Redirect to={`/${locale}/`} />
			</Route>
		</Switch>
	);
}
