import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const SurveyContext = createContext(null);
export default SurveyContext;

export function SurveyContextProvider({ children }) {
	const [survey, setSurvey] = useState(null);
	const [questions, setQuestions] = useState([]);
	const [token, setToken] = useState(null);
	const [hash, setHash] = useState(null);
	const [debugMode, setDebugMode] = useState(false);
	const [cacheCount, setCacheCount] = useState(0);

	const increaseCacheCount = () => {
		setCacheCount(cacheCount + 1);
	};

	const toggleDebugMode = () => {
		setDebugMode(!debugMode);
	};

	return (
		<SurveyContext.Provider
			value={{
				survey,
				setSurvey,
				questions,
				setQuestions,
				token,
				setToken,
				hash,
				setHash,
				debugMode,
				toggleDebugMode,
				cacheCount,
				increaseCacheCount,
			}}
		>
			{children}
		</SurveyContext.Provider>
	);
}

SurveyContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
