import PropTypes from 'prop-types';

export const surveyPropTypes = PropTypes.shape({
	id: PropTypes.number.isRequired,
	start: PropTypes.string,
	showResults: PropTypes.bool,
	end: PropTypes.string,
});

export const questionPropTypes = PropTypes.shape({
	id: PropTypes.number.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
		}),
	).isRequired,
	outputType: PropTypes.string.isRequired,
	question: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['singleSelect', 'multiSelect', 'boolean']).isRequired,
});
