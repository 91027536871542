import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SurveyContext from '../contexts/SurveyContext';
import { get } from '../utils/request';

import { LargeText, SmallText } from '../components';
import {
	Clear as ErrorPlaceholder,
	HourGlass as ValidatePlaceholder,
} from '../assets/images/icons';

const STATES = {
	VALIDATE: 'validate',
	SUCCESS: 'success',
	ERROR: 'error',
};

const IconWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	justify-self: center;
	flex: 1 1 auto;

	svg {
		width: 10rem;
		height: auto;

		fill: #fff;
		margin-bottom: 2em;
	}
`;

export default function SurveyStart() {
	const { t } = useTranslation();
	const { locale, surveyId } = useParams();
	const { push } = useHistory();

	const { hash, token } = useContext(SurveyContext);

	const [errorCode, setErrorCode] = useState(null);
	const [state, setState] = useState(STATES.VALIDATE);

	useEffect(() => {
		get(`/${locale}/surveys/${surveyId}/check.json`, { hash, token }, true)
			.then((response) => {
				if (response.data === true) setState(STATES.SUCCESS);
				else {
					setState(STATES.ERROR);
					setErrorCode(response.error.code);
				}
			})
			.catch(() => {
				push(`/${locale}/error/1588141963941`);
			});
	});

	switch (state) {
		case STATES.SUCCESS: {
			return <Redirect to={`/${locale}/surveys/${surveyId}/questions`} />;
		}
		case STATES.ERROR: {
			return (
				<IconWrapper>
					<ErrorPlaceholder />
					<LargeText color="#fff">{t('surveys.start.error')}</LargeText>
					<SmallText color="#fff">{t(`api.${errorCode}`)}</SmallText>
				</IconWrapper>
			);
		}
		case STATES.VALIDATE:
		default: {
			return (
				<IconWrapper>
					<ValidatePlaceholder />
					<LargeText color="#fff">{t('surveys.start.validate')}</LargeText>
				</IconWrapper>
			);
		}
	}
}
