import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function Translate({ id }) {
	const { t } = useTranslation();

	return <span>{t(id)}</span>;
}

Translate.propTypes = {
	id: PropTypes.string.isRequired,
};
