import qs from 'qs';

import { API_BASE, API_AUTH_TOKEN } from './config';

const getCache = {};
const postCache = {};

export function get(url, query = {}, cache = false) {
	let uri = url;
	if (Object.keys(query).length > 0) {
		uri += `?${qs.stringify(query)}`;
	}

	if (cache === false) {
		return internalFetch(uri, 'GET');
	}

	if (typeof getCache[uri] === 'undefined') {
		getCache[uri] = internalFetch(uri, 'GET');
	}
	return getCache[uri];
}

export function post(url, data, cache = false) {
	if (cache === false) {
		return internalFetch(url, 'POST', data);
	}

	const cacheId = `${url}::${JSON.stringify(data)}`;

	if (typeof postCache[cacheId] === 'undefined') {
		postCache[cacheId] = internalFetch(url, 'POST', data);
	}
	return postCache[cacheId];
}

export function clearCache(url, data = {}, type = 'GET') {
	let cacheId = url;
	if (type === 'GET') {
		if (Object.keys(data).length > 0) {
			cacheId += `?${qs.stringify(data)}`;
		}

		delete getCache[cacheId];
	} else if (type === 'POST') {
		cacheId += `::${JSON.stringify(data)}`;

		delete postCache[cacheId];
	}
}

async function internalFetch(uri, method, data) {
	const response = await fetch(`${API_BASE}${uri}`, {
		method,
		headers: {
			'X-Auth-Token': API_AUTH_TOKEN,
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		...(data && { body: JSON.stringify(data) }),
	});

	const json = await response.json();

	if (!response.ok) {
		console.warn(json.error.message);
	}

	return json;
}
