import React from 'react';

export const Clear = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M19 6.4L17.6 5 12 10.6 6.4 5 5 6.4l5.6 5.6L5 17.6 6.4 19l5.6-5.6 5.6 5.6 1.4-1.4-5.6-5.6z" />
	</svg>
);

export const HourGlass = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M6 2v6l4 4-4 4v6h12v-6l-4-4 4-4V2H6zm10 14.5V20H8v-3.5l4-4 4 4zm-4-5l-4-4V4h8v3.5l-4 4z" />
	</svg>
);

export const Done = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
	</svg>
);

export const MailOutline = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M20 4H4a2 2 0 00-2 2v12c0 1.1.9 2 2 2h16a2 2 0 002-2V6a2 2 0 00-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z" />
	</svg>
);

export const InsertPhoto = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M21 19V5a2 2 0 00-2-2H5a2 2 0 00-2 2v14c0 1.1.9 2 2 2h14a2 2 0 002-2zM8.5 13.5l2.5 3 3.5-4.5 4.5 6H5l3.5-4.5z" />
	</svg>
);

export const Assignment = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			d="M19 3h-4.2A3 3 0 0012 1a3 3 0 00-2.8 2H5a2 2 0 00-2 2v14c0 1.1.9 2 2 2h14a2 2 0 002-2V5a2 2 0 00-2-2zm-7 0c.6 0 1 .5 1 1s-.5 1-1 1-1-.5-1-1 .5-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"
			fill="#01B3FB"
		/>
	</svg>
);

export const Edit = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			d="M3 17.3V21h3.8l11-11-3.7-3.8L3 17.2zM20.7 7c.4-.4.4-1 0-1.4l-2.3-2.3a1 1 0 00-1.4 0L15 5 19 9 20.7 7z"
			fill="#01B3FB"
		/>
	</svg>
);

export const PieChart = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M11 2v20c-5-.5-9-4.8-9-10s4-9.5 9-10zm2 0v9h9a10 10 0 00-9-9zm0 11v9a10 10 0 009-9h-9z" />
	</svg>
);

export const BarChart = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z" />
		<path d="M0 0h24v24H0z" fill="none" />
	</svg>
);

export const Speed = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M20.4 8.6l-1.2 1.8a8 8 0 01-.3 7.6H5.1A8 8 0 0115.6 6.8l1.8-1.2A10 10 0 003.4 19 2 2 0 005 20h13.8a2 2 0 001.8-1 10 10 0 00-.3-10.4zm-9.8 6.8a2 2 0 002.8 0L19.1 7l-8.5 5.7a2 2 0 000 2.8z" />
	</svg>
);
