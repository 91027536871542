import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LargeText, Text, Button } from '../components';

export default function Error() {
	const { t } = useTranslation();
	const { replace } = useHistory();
	const { locale, error } = useParams();

	return (
		<>
			<LargeText color="#485156">{t('navigation.error')}</LargeText>
			<Text color="#485156">{t(`api.${error}`)}</Text>

			<Button primary onClick={() => replace(`/${locale}/`)}>
				{t('navigation.home')}
			</Button>
		</>
	);
}
