import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
// import * as serviceWorker from './serviceWorker';

Sentry.init({
	dsn: 'https://f35ad356b4044fd6a47aa5e2ae3f6384@o15304.ingest.sentry.io/5244893',
	ignoreErrors: ['ResizeObserver', 'ResizeObserver loop limit exceeded'],
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
