import React from 'react';
import styled from '@emotion/styled';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, LargeText } from '../components';

import { EndSurvey } from '../assets/images/svg';

const SuccessWrapper = styled.div`
	display: flex;
	height: calc(100vh - 80px - 2rem);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	justify-self: center;
	flex: 1 1 auto;

	svg {
		margin-bottom: 2em;
		width: 149px;
		height: 167px;
		fill: #fff;
	}

	> div {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		justify-content: center;
		align-items: center;
	}
`;

export default function SurveyEnd() {
	const { push } = useHistory();
	const { locale, surveyId } = useParams();
	const { t } = useTranslation();

	return (
		<SuccessWrapper>
			<div>
				<EndSurvey />
				<LargeText color="#fff" center>
					{t('surveys.end.text')}
				</LargeText>
			</div>
			<Button onClick={() => push(`/${locale}/surveys/${surveyId}`)}>
				{t('surveys.end.button')}
			</Button>
		</SuccessWrapper>
	);
}
