import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { COLORS } from '../utils/config';
import easeFn from '../utils/ease';

const Wrapper = styled.div`
	width: 100%;
	position: relative;
	margin: 1rem 0;
	line-height: 0;
	opacity: 0;

	transition: opacity ease-in-out ${({ animationDuration }) => animationDuration}ms;

	&.active {
		opacity: 1;
	}
`;

const Percentage = styled.span`
	font-family: 'Helvetica Now Display W01 Bold';
	font-size: 1.25rem;
	line-height: 1;
	color: #485156;
	transform-origin: bottom left;

	@media (min-width: 600px) {
		font-size: 2rem;
		line-height: 1;
	}
	@media (min-width: 800px) {
		font-size: 3rem;
	}
`;

const Legend = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	font-size: 0.9rem;
`;

const Option = styled.span`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: absolute;
	bottom: 0;
	left: 0;
	line-height: 1.2;

	min-width: ${({ width }) => width / 5}px;

	transform: translateX(${({ position }) => position.x || 0}px)
		translateX(${({ position, width }) => (position.x < width / 2 ? '-50%' : '-25%')})
		translateY(${({ position }) => position.y}px) translateY(50%);
`;

export default class DonutChart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			slices: [],
			isActive: false,
		};

		this.canvasRef = createRef();
		this.ctx = null;

		this.raf = null;
		this.animationStart = 0;
		this.redrawTimeout = null;
	}

	componentDidMount() {
		this.ctx = this.canvasRef.current.getContext('2d');

		this.calculateDonut();

		if (this.props.isActive) this.startAnimation();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.width !== this.props.width) {
			clearTimeout(this.redrawTimeout);
			this.calculateDonut();

			if (this.props.isActive && this.state.isActive) {
				this.redrawTimeout = setTimeout(() => this.startAnimation(), 500);
			}
		}
		if (this.state.isActive === this.props.isActive) {
			return;
		}
		if (!prevProps.isActive && this.props.isActive) {
			this.startAnimation();
		} else if (prevProps.isActive && !this.props.isActive) {
			this.setState({ isActive: false });
		}
	}

	calculateDonut() {
		const { options, offset, scale, width, ratio } = this.props;

		const height = width / ratio;

		this.canvasRef.current.setAttribute('width', width);
		this.canvasRef.current.setAttribute('height', height);

		const [offsetX, offsetY] = offset.map((_offset, index) =>
			index === 0 ? width * _offset : height * _offset,
		);

		let realScale = scale;
		const textPosScale = { x: 1, y: 1 };

		if (window.innerWidth < 420) {
			realScale *= 0.6;
			textPosScale.x = 1.5;
			textPosScale.y = 1.7;
		}

		const lineWidth = (Math.min(width, height) / 3) * realScale;

		const radius = ((Math.min(width, height) - lineWidth) / 2.2) * realScale;
		const cx = width / 2 + offsetX;
		const cy = height / 2 + offsetY;

		// we can have more than 100% (every option can get up to 100%)
		const sumPercentage = options.reduce((sum, { percentage }) => sum + percentage, 0);

		// let the donut start on top (-90deg => Math.PI * 2 - Math.PI / 2)
		let currentPosition = Math.PI * 1.5;

		this.ctx.lineWidth = lineWidth;

		const slices = options.map(({ percentage }, index) => {
			const normed = percentage / sumPercentage;

			const from = currentPosition;
			const to = currentPosition - Math.PI * 2 * normed;
			const center = to - (to - from) / 2;

			currentPosition = to;

			return {
				percentage: {
					x: cx + Math.cos(center) * radius * 2.25 * textPosScale.x,
					y: cy + Math.sin(center) * radius * 2.25 * textPosScale.y,
				},
				slice: {
					from,
					to,
					color: COLORS[index % COLORS.length],
				},
			};
		});

		this.setState({
			cx,
			cy,
			radius,
			slices,
			lineWidth,
			width,
			height,
		});
	}

	drawSlice({ from, to, color }) {
		const { cx, cy, radius, lineWidth } = this.state;

		this.ctx.lineWidth = lineWidth;
		this.ctx.strokeStyle = color;
		this.ctx.beginPath();
		this.ctx.arc(cx, cy, radius, from, to, color);
		this.ctx.stroke();

		this.ctx.lineWidth = lineWidth * 0.15;
		this.ctx.strokeStyle = 'rgba(0,0,0,0.2)';

		const darkenLineRadius = radius - lineWidth / 2 + (lineWidth * 0.15) / 2;

		this.ctx.beginPath();
		this.ctx.arc(cx, cy, darkenLineRadius, from, to, color);
		this.ctx.stroke();
	}

	startAnimation() {
		setTimeout(() => {
			this.animationStart = Date.now();
			this.animate();

			this.setState({ isActive: true });
		}, 250);
	}

	animate() {
		const { width, height, slices } = this.state;
		const { animationDuration } = this.props;

		let progress = (Date.now() - this.animationStart) / animationDuration;

		if (progress >= 1) progress = 1;

		// clear the canvas
		this.ctx.clearRect(0, 0, width, height);

		const ease = easeFn(progress);

		slices.forEach(({ slice: { from, to, color } }) => {
			this.drawSlice({
				from: from * ease,
				to: to * ease,
				color,
			});
		});

		if (progress < 1) this.raf = window.requestAnimationFrame(() => this.animate(), 100);
	}

	render() {
		const { slices, isActive } = this.state;
		const { options, width, animationDuration } = this.props;

		return (
			<Wrapper
				ref={this.wrapperRef}
				className={isActive ? 'active' : ''}
				animationDuration={animationDuration}
			>
				<canvas ref={this.canvasRef} />

				<Legend>
					{slices.length &&
						options.map(({ percentage, value }, index) => (
							<Option
								key={value}
								color={COLORS[index]}
								position={slices[index].percentage}
								width={width}
							>
								<Percentage>{percentage}%</Percentage>
								{value}
							</Option>
						))}
				</Legend>
			</Wrapper>
		);
	}
}

DonutChart.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			percentage: PropTypes.number,
			givenAnswers: PropTypes.number,
		}),
	),
	scale: PropTypes.number,
	offset: PropTypes.arrayOf(PropTypes.number),
	ratio: PropTypes.number,
	width: PropTypes.number,
	isActive: PropTypes.bool,
	animationDuration: PropTypes.number,
};

DonutChart.defaultProps = {
	scale: 0.5,
	offset: [0, 0],
	ratio: 16 / 10,
	width: 100,
	animationDuration: 500,
};
