import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { questionPropTypes } from '../utils/prop-types';

import { LargeText, Text } from './Text';
import { Options, HiddenInput, OptionWrapper, Option } from './StyledQuestionComponents';

const CheckBox = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22 ">
		<circle className="background" r="9" cx="10" cy="10" fill="#018AC2" />
		<circle className="inner-circle" r="5.725" cx="10" cy="10" fill="#fff" />
		<circle className="outer-circle" r="9" cx="10" cy="10" stroke="#fff" fill="none" />
	</svg>
);

const checkedStyles = css`
	.inner-circle,
	.outer-circle {
		visibility: visible;
		opacity: 1;
	}
`;

const iconStyles = css`
	.inner-circle,
	.outer-circle {
		visibility: hidden;
		opacity: 0;
		transition: opacity 250ms ease-in-out;
	}
`;

const hoverStyles = css`
	.outer-circle {
		visibility: visible;
		opacity: 1;
	}
`;

export default function SingleSelectQuestion({ question, onChange }) {
	return (
		<>
			<LargeText color="#fff" center margin={[1, 0]}>
				{question.question}
			</LargeText>

			<Options>
				{question.options.map(({ value }, index) => (
					<OptionWrapper key={`${question.question}-${value}`}>
						<HiddenInput
							id={`single-select-question-${index}`}
							type="radio"
							name="singleSelect"
							value="value"
							onChange={() => {
								onChange(value);
							}}
							checkedStyles={checkedStyles}
						/>
						<Option
							htmlFor={`single-select-question-${index}`}
							iconStyles={iconStyles}
							hoverStyles={hoverStyles}
						>
							<CheckBox />
							<Text center>{value}</Text>
						</Option>
					</OptionWrapper>
				))}
			</Options>
		</>
	);
}

SingleSelectQuestion.propTypes = {
	question: questionPropTypes,
	onChange: PropTypes.func,
};
