import styled from '@emotion/styled';

export const LargeText = styled.span`
    font-family: 'Helvetica Now Display W01 ${({ weight }) => weight || 'Light'}';
    font-size: 1.25rem;
    line-height: 1.75rem;
	text-align: ${({ center }) => (center ? 'center' : 'left')};
    color: ${({ color }) => color || '#fff'};
	margin: ${({ margin }) => (margin && margin.map((m) => `${m}rem`).join(' ')) || 0};
`;

export const Text = styled.span`
    font-family: 'Helvetica Now Display W01 ${({ weight }) => weight || 'Rg'}';
    font-size: 0.875rem;
    line-height: 1rem;
	text-align: ${({ center }) => (center ? 'center' : 'left')};
    color: ${({ color }) => color || '#fff'};
	margin: ${({ margin }) => (margin && margin.map((m) => `${m}rem`).join(' ')) || 0};
`;

export const SmallText = styled.span`
    font-family: 'Helvetica Now Display W01 ${({ weight }) => weight || 'Bold'}';
    font-size: 0.75rem;
    line-height: 0.875;
	text-align: ${({ center }) => (center ? 'center' : 'left')};
    color: ${({ color }) => color || '#fff'};
	margin: ${({ margin }) => (margin && margin.map((m) => `${m}rem`).join(' ')) || 0};
`;
