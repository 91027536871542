import { css } from '@emotion/core';

import HelveticaThinWoff from './assets/fonts/e4ec8fc5-9a4d-422b-a031-740b0e21f14f.woff';
import HelveticaLightWoff from './assets/fonts/cd6178ba-ad0f-4cd3-8f0c-05b2c51b9bc7.woff';
import HelveticaRgWoff from './assets/fonts/784fdfff-9e52-4f65-af2a-c0892907a9d4.woff';
import HelveticaMdWoff from './assets/fonts/b19bb50a-5542-494b-ac36-b4d6920e7483.woff';
import HelveticaBoldWoff from './assets/fonts/79e75c13-a7cb-4154-83dc-4dfb2aec1b11.woff';

import HelveticaThinWoff2 from './assets/fonts/0823bbe6-3830-440b-9d3e-6d548239cf65.woff2';
import HelveticaLightWoff2 from './assets/fonts/284e66f2-886a-4e0f-a801-ff7b25fd52af.woff2';
import HelveticaRgWoff2 from './assets/fonts/72d42bf4-2173-4e9d-b3f5-2e97d45eac01.woff2';
import HelveticaMdWoff2 from './assets/fonts/f5b78362-04e7-42f9-9c7c-1a249964cc80.woff2';
import HelveticaBoldWoff2 from './assets/fonts/f0cbab32-010c-4ff1-8be8-7d74011f2548.woff2';

export default css`
	/* Import Helvetica */
	@import url('https://fast.fonts.net/t/1.css?apiType=css&projectid=b54168da-fb74-4ca1-a11d-06f037edcb7a');
	@font-face {
		font-family: 'Helvetica Now Display W01 Thin';
		src: url(${HelveticaThinWoff2}) format('woff2'), url(${HelveticaThinWoff}) format('woff');
	}
	@font-face {
		font-family: 'Helvetica Now Display W01 Light';
		src: url(${HelveticaLightWoff2}) format('woff2'), url(${HelveticaLightWoff}) format('woff');
	}
	@font-face {
		font-family: 'Helvetica Now Display W01 Rg';
		src: url(${HelveticaRgWoff2}) format('woff2'), url(${HelveticaRgWoff}) format('woff');
	}
	@font-face {
		font-family: 'Helvetica Now Display W01 Md';
		src: url(${HelveticaMdWoff2}) format('woff2'), url(${HelveticaMdWoff}) format('woff');
	}
	@font-face {
		font-family: 'Helvetica Now Display W01 Bold';
		src: url(${HelveticaBoldWoff2}) format('woff2'), url(${HelveticaBoldWoff}) format('woff');
	}

	html,
	body {
		font-size: 12px;
		font-size: calc(5.5px + 2.5vw);

		@media (min-width: 420px) {
			font-size: 16px;
		}
	}

	body {
		font-family: 'Helvetica Now Display W01 Rg', Arial, Helvetica, sans-serif;
		margin: 0;
		padding: 0;
	}

	* {
		box-sizing: border-box;
	}
`;
