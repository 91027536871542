export const de = {
	1587709791558: 'Die angefragten Daten konnten nicht gefunden werden. Möglicherweise wurde die Umfrage gelöscht.', // Die angefragte Resource wurde nicht gefunden.
	1587984459737: 'Fehler beim Übertragen der Daten.', // Die verwendete Request-Methode ist ungültig. => Sentry
	1587716689887: 'Fehler beim Übertragen der Daten.', // Das Auth-Token wurde nicht mit dem Request mitgeschickt. => Sentry
	1587717600247: 'Fehler beim Übertragen der Daten.', // Das verwendete Auth-Token ist ungültig. => Sentry
	1587717657750: 'Fehler beim Übertragen der Daten.', // Das verwendete Auth-Token wurde deaktiviert. => Sentry
	1588584022003: 'Fehler beim Übertragen der Daten.', // Das verwendete Auth-Token hat keine Schreibberechtigungen. => Sentry
	1587650709725: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.', // Der E-Mail Parameter fehlt.
	1587650886322: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.', // Ungültige E-Mail-Adresse
	1587651027669: 'Bitte verwenden Sie Ihre gültige TenneT-E-Mail-Adresse.', // Ungültiger Host
	1587971725344: 'Fehler beim Übertragen der Daten.', // Die verwendete Umfragen-ID ist ungültig. Diese darf nur aus Ziffern bestehen. => Sentry
	1588761279090: 'Die angefragte Umfrage hat noch nicht gestartet.',
	1589442448078: 'Die angefragte Umfrage wurde bereits beendet.',
	1588884091149: 'Fehler beim Übertragen der Daten.', // Leerer Anfrage-Body. => Sentry
	1571737786684: 'Fehler beim Übertragen der Daten.', // JSON-Fehler => Sentry
	1571737444313: 'Fehler beim Übertragen der Daten.', // JSON-Fehler => Sentry
	1571738007196: 'Fehler beim Übertragen der Daten.', // JSON-Fehler => Sentry
	1571738042825: 'Fehler beim Übertragen der Daten.', // JSON-Fehler => Sentry
	1571738172159: 'Fehler beim Übertragen der Daten.', // JSON-Fehler => Sentry
	1571738268747: 'Fehler beim Übertragen der Daten.', // JSON-Fehler => Sentry
	1571738306717: 'Fehler beim Übertragen der Daten.', // JSON-Fehler => Sentry
	1571738406295: 'Fehler beim Übertragen der Daten.', // JSON-Fehler => Sentry
	1571738491648: 'Fehler beim Übertragen der Daten.', // JSON-Fehler => Sentry
	1571738663163: 'Fehler beim Übertragen der Daten.', // JSON-Fehler => Sentry
	1571738730947: 'Fehler beim Übertragen der Daten.', // JSON-Fehler => Sentry
	1588002985239: 'Fehler beim Übertragen der Daten.', // Der E-Mail Parameter fehlt. => Sentry
	1588141963941: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.', // DB-Fehler
	1588170895488: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.', // Fehler beim Mail-Versand
	1588587335547: 'Fehler beim Übertragen der Daten.', // Parameter fehlen => Sentry
	1588587338756: 'Fehler beim Übertragen der Daten.', // Ungültige Parameter => Sentry
	1588588675929: 'Fehler beim Validieren der Daten.', // Der aktuelle Teilnehmer konnte nicht gefunden werden oder die Überprüfung des Tokens schlug fehl.
	1588589378621: 'Sie haben bereits an dieser Umfrage teilgenommen.', // Teilnehmer (Hash + Token) hat bereits an der Umfrage teilgenommen.
};

export const en = {
	1587709791558: 'The requested data could not be found. Maybe the survey has been deleted.', // Resource not found
	1587984459737: 'Error during data transmission.', // Invalid request method => Sentry
	1587716689887: 'Error during data transmission.', // Auth token missing => Sentry
	1587717600247: 'Error during data transmission.', // Invalid auth token => Sentry
	1587717657750: 'Error during data transmission.', // Auth token disabled => Sentry
	1588584022003: 'Error during data transmission.', // Auth token not allowed to perform write actions => Sentry
	1587650709725: 'Please enter a valid mail address.', // Missing mail parameter
	1587650886322: 'Please enter a valid mail address.', // Invalid mail address
	1587651027669: 'Please use your valid TenneT mail address.', // Invalid mail host
	1587971725344: 'Error during data transmission.', // Invalid survey id. Only digits allowed => Sentry
	1588761279090: 'The requested survey has not started yet.',
	1589442448078: 'The requested survey has already been completed.',
	1588884091149: 'Error during data transmission.', // Empty request body. => Sentry
	1571737786684: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571737444313: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738007196: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738042825: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738172159: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738268747: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738306717: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738406295: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738491648: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738663163: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738730947: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1588002985239: 'Error during data transmission.', // Missing mail parameter => Sentry.
	1588141963941: 'An error occurred. Please try again.', // DB error
	1588170895488: 'An error occurred. Please try again.', // Error during mail delivery
	1588587335547: 'Error during data transmission.', // Missing parameters => Sentry
	1588587338756: 'Error during data transmission.', // Invalid parameters => Sentry
	1588588675929: 'Error while validating the data.', // The current participant can not be found or the token does not match.
	1588589378621: 'You already participated in this survey.',
};

export const nl = {
	1587709791558: 'The requested data could not be found. Maybe the survey has been deleted.', // Resource not found
	1587984459737: 'Error during data transmission.', // Invalid request method => Sentry
	1587716689887: 'Error during data transmission.', // Auth token missing => Sentry
	1587717600247: 'Error during data transmission.', // Invalid auth token => Sentry
	1587717657750: 'Error during data transmission.', // Auth token disabled => Sentry
	1588584022003: 'Error during data transmission.', // Auth token not allowed to perform write actions => Sentry
	1587650709725: 'Please enter a valid mail address.', // Missing mail parameter
	1587650886322: 'Please enter a valid mail address.', // Invalid mail address
	1587651027669: 'Please use your valid TenneT mail address.', // Invalid mail host
	1587971725344: 'Error during data transmission.', // Invalid survey id. Only digits allowed => Sentry
	1588761279090: 'The requested survey has not started yet.',
	1589442448078: 'The requested survey has already been completed.',
	1588884091149: 'Error during data transmission.', // Empty request body. => Sentry
	1571737786684: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571737444313: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738007196: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738042825: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738172159: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738268747: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738306717: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738406295: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738491648: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738663163: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1571738730947: 'Error during data transmission.', // An error with the transmitted JSON body occurred. => Sentry
	1588002985239: 'Error during data transmission.', // Missing mail parameter => Sentry.
	1588141963941: 'An error occurred. Please try again.', // DB error
	1588170895488: 'An error occurred. Please try again.', // Error during mail delivery
	1588587335547: 'Error during data transmission.', // Missing parameters => Sentry
	1588587338756: 'Error during data transmission.', // Invalid parameters => Sentry
	1588588675929: 'Error while validating the data.', // The current participant can not be found or the token does not match.
	1588589378621: 'You already participated in this survey.',
};

export const sentryIds = [
	1587984459737,
	1587716689887,
	1587717600247,
	1587717657750,
	1588584022003,
	1587971725344,
	1588884091149,
	1571737786684,
	1571737444313,
	1571738007196,
	1571738042825,
	1571738172159,
	1571738268747,
	1571738306717,
	1571738406295,
	1571738491648,
	1571738663163,
	1571738730947,
	1588002985239,
	1588587335547,
	1588587338756,
];

export default {
	de,
	en,
	nl,
	sentryIds,
};
