import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';

export default function Info() {
	const { t } = useTranslation();
	const { goBack } = useHistory();

	return (
		<>
			<h2>{t('info.headline')}</h2>
			<div dangerouslySetInnerHTML={{ __html: t('info.content') }} />

			<Button primary onClick={() => goBack()}>
				{t('navigation.back')}
			</Button>
		</>
	);
}
