import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from '@emotion/styled';

import SurveyContext from '../contexts/SurveyContext';
import { LargeText, Button, Slider } from '../components';

import { NoSurvey, ParticipateSurvey } from '../assets/images/svg';
import inIframe from '../utils/in-iframe';

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-content: center;
	width: 100%;
	min-height: 200px;

	svg {
		width: 150px;
		height: auto;
	}
`;

export default function Home() {
	const { t } = useTranslation();
	const { locale } = useParams();
	const { push } = useHistory();
	const { survey, questions } = useContext(SurveyContext);

	if (survey) {
		let showParticipationButton = true;
		if (survey.end !== null) {
			const now = Date.parse(new Date());
			const endDate = Date.parse(survey.end);
			if (endDate < now) {
				showParticipationButton = false;
			}
		}

		return (
			<>
				{survey.showResult ? (
					<Slider participants={survey.participants} questions={questions} />
				) : (
					<>
						<IconWrapper>
							<ParticipateSurvey />
						</IconWrapper>
						<LargeText
							color="#485156"
							center
							dangerouslySetInnerHTML={{ __html: t('surveys.newSurvey') }}
						/>
					</>
				)}

				{showParticipationButton && (
					<Button
						primary
						onClick={() => {
							if (inIframe()) {
								window
									.open(`${window.location.href}/registration`, '_blank')
									.focus();
							} else {
								push(`/${locale}/surveys/${survey.id}/registration`);
							}
						}}
					>
						{t('surveys.participate')}
					</Button>
				)}

				<Button
					primary
					onClick={() => {
						if (inIframe())
							window.open(`${window.location.href}/info`, '_blank').focus();
						else push(`/${locale}/info`);
					}}
				>
					{t('surveys.information')}
				</Button>
			</>
		);
	}

	return (
		<>
			<IconWrapper>
				<NoSurvey />
			</IconWrapper>
			<LargeText
				color="#485156"
				center
				dangerouslySetInnerHTML={{ __html: t('surveys.noSurvey') }}
			/>
		</>
	);
}
