import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from '../assets/translations/de.json';
import en from '../assets/translations/en.json';
import nl from '../assets/translations/nl.json';

import { de as apiDe, en as apiEn, nl as apiNl } from './errors';
import { LANGUAGES } from './config';

// the default language
export const lng = (() => {
	const lang = window.navigator.languages
		? window.navigator.languages.find((l) => LANGUAGES.indexOf(l.substr(0, 2)) > -1)
		: // for ie only
		  window.navigator.language;

	if (lang) {
		return lang.substr(0, 2);
	}
	return LANGUAGES[0];
})();

i18n.use(initReactI18next).init({
	resources: {
		en: { translation: { ...en, api: apiEn } },
		de: { translation: { ...de, api: apiDe } },
		nl: { translation: { ...nl, api: apiNl } },
	},
	lng,
	fallbackLng: LANGUAGES[0],

	interpolation: {
		escapeValue: false,
	},

	debug: process.env.NODE_ENV === 'development',
});

i18n.on('languageChanged', (lang) => {
	document.documentElement.lang = lang;
});

export default i18n;
