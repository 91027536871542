import styled from '@emotion/styled';

export const Options = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 320px;
`;

export const HiddenInput = styled.input`
	visibility: hidden;
	display: none;

	:checked ~ label {
		background-color: #0595d0;

		${({ checkedStyles }) => checkedStyles}
	}
`;

export const OptionWrapper = styled.div`
	width: 100%;
	overflow: hidden;

	:not(:last-of-type) {
		margin-bottom: 1px;
	}

	:first-of-type {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	:last-of-type {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
`;

export const Option = styled.label`
	background-color: #01a6e9;
	min-height: 60px;
	width: 100%;

	display: flex;
	align-items: center;
	flex-direction: row;
	padding: 20px;

	transition: background-color 250ms ease-in-out;

	svg {
		width: 20px;
		height: 20px;
		flex: 0 0 20px;
		margin-right: 20px;
		${({ iconStyles }) => iconStyles}
	}

	span {
		flex: 1 1 auto;
	}

	:hover {
		background-color: #0595d0;
		${({ hoverStyles }) => hoverStyles}
	}
`;
