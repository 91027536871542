import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledButton = styled.button`
	font-family: 'Helvetica Now Display W01 Bold';
	font-size: 0.875rem;
	text-transform: uppercase;
	color: ${({ primary }) => (primary ? '#fff' : '#01B3FB')};
	transition: opacity 200ms ease-in-out;

	border: 0;
	border-radius: 1.5rem;
	cursor: pointer;
	outline: none;
	margin: 0.5rem 0;

	height: 48px;
	min-width: 240px;
	max-width: 100%;

	background-color: ${({ primary }) => (primary ? '#01B3FB' : '#fff')};

	justify-self: ${({ justify }) => justify || 'flex-start'};
	align-self: center;

	:disabled {
		cursor: default;
		opacity: 0.5;
	}
`;

export default function Button({ children, ...props }) {
	return (
		<StyledButton type="button" {...props}>
			{children}
		</StyledButton>
	);
}

Button.propTypes = {
	children: PropTypes.node,
};
