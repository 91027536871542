import React from 'react';
import PropTypes from 'prop-types';

import BarChart from './BarChart';
import TachoChart from './TachoChart';
import DonutChart from './DonutChart';

const OUTPUT_MAP = {
	lines: BarChart,
	tacho: TachoChart,
	donut: DonutChart,
};

export default function Result({ outputType, ...props }) {
	const OutputComponent = OUTPUT_MAP[outputType] || null;

	return <OutputComponent {...props} />;
}

Result.propTypes = {
	outputType: PropTypes.oneOf([...Object.keys(OUTPUT_MAP)]),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			percentage: PropTypes.number.isRequired,
		}),
	),
	width: PropTypes.number,
	isActive: PropTypes.bool,
	useGivenAnswers: PropTypes.bool,
};
