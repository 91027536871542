import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { LargeText, SmallText } from './Text';

const InputLabel = styled.label`
	display: flex;
	flex-direction: column;
`;

const InputIcon = styled.div`
	width: 4rem;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		width: 1.5rem;
		height: auto;
	}
`;

const StyledInput = styled.input`
	height: 60px;
	min-width: 240px;
	max-width: 100%;
	border: 0;
	outline: none;
	box-sizing: border-box;
	font-size: 14px;
	color: ${({ error }) => (error ? '#E90118' : '#bfbfbf')};
`;

const InputWrapper = styled.div`
	transition: all 300ms ease;
	margin: 1rem 0;
	line-height: 0;
	display: flex;

	background-color: #fff;
	border-radius: 0.5rem;
	overflow: hidden;

	border: 2px solid transparent;

	:hover {
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
		color: gray;
	}

	:focus-within {
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
		color: gray;
		border-color: #01a6e9;
	}
`;

export default function Input({
	label,
	type,
	placeholder,
	inputRef,
	icon,
	message,
	error,
	onChange,
	onEnter,
}) {
	return (
		<InputLabel>
			<LargeText center>{label}</LargeText>
			<InputWrapper>
				{icon && <InputIcon>{icon}</InputIcon>}
				<StyledInput
					type={type}
					ref={inputRef}
					placeholder={placeholder}
					error={error}
					onChange={onChange}
					onKeyUp={(e) => e.key.toLowerCase() === 'enter' && onEnter()}
				/>
			</InputWrapper>
			{message && <SmallText>{message}</SmallText>}
		</InputLabel>
	);
}

Input.propTypes = {
	label: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	inputRef: PropTypes.object,
	placeholder: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	message: PropTypes.string,
	error: PropTypes.bool,
	onChange: PropTypes.func,
	onEnter: PropTypes.func,
};
