import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useHistory, useParams } from 'react-router-dom';
import logo from '../assets/images/TennetLogo.png';

import i18n from '../utils/i18n';
import inIframe from '../utils/in-iframe';
import SurveyContext from '../contexts/SurveyContext';

const Logo = styled.img`
	height: 100%;
`;

const Header = styled.div`
	height: 80px;
	padding: 1.5rem;
	color: white;
	background: linear-gradient(to right, rgba(1, 160, 67, 1) 5%, rgba(1, 179, 251, 1) 100%) left
		bottom white no-repeat;
	background-size: 100% 7px;
	flex: 0 0 auto;
`;

function LanguageButton({ lang }) {
	const { push } = useHistory();
	const { setSurvey, setQuestions, increaseCacheCount } = useContext(SurveyContext);

	return (
		<button
			type="button"
			onClick={() => {
				setSurvey(null);
				setQuestions([]);
				increaseCacheCount();
				i18n.changeLanguage(lang);
				push(`/${lang}/`);
			}}
		>
			{lang}
		</button>
	);
}

LanguageButton.propTypes = {
	lang: PropTypes.oneOf(['de', 'en', 'nl']).isRequired,
};

export default function AppHeader() {
	const { push } = useHistory();
	const { locale } = useParams();
	const { debugMode, toggleDebugMode } = useContext(SurveyContext);

	if (inIframe()) return <></>;

	return (
		<Header>
			<Logo src={logo} alt="TenneT Logo" onClick={() => push(`/${locale}/`)} />
			{process.env.NODE_ENV === 'development' && (
				<>
					<LanguageButton lang="de" />
					<LanguageButton lang="en" />
					<LanguageButton lang="nl" />
					<label style={{ color: '#000' }}>
						<input
							type="checkbox"
							onChange={toggleDebugMode}
							defaultChecked={debugMode}
						/>
						Debug-Modus verwenden?
					</label>
				</>
			)}
		</Header>
	);
}
