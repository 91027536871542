import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { COLORS } from '../utils/config';
import easeFn from '../utils/ease';

const Wrapper = styled.div`
	width: 100%;
	position: relative;
	margin: 1rem 0;

	opacity: 0;

	transition: opacity ease-in-out ${({ animationDuration }) => animationDuration}ms;

	&.active {
		opacity: 1;
	}
`;

const Container = styled.div`
	width: 100%;
	position: relative;
	line-height: 0;
`;

const Percentage = styled.span`
	position: absolute;
	bottom: 0;
	left: 0;
	font-family: 'Helvetica Now Display W01 Bold';
	font-size: 1.25rem;
	line-height: 1.5;
	text-align: center;
	color: #485156;
	transform: translateX(${({ x }) => x}px) translateY(${({ y }) => y}px);
	transform-origin: bottom left;
	width: ${({ width }) => width}%;

	@media (min-width: 600px) {
		font-size: 2rem;
		line-height: 1;
	}
	@media (min-width: 800px) {
		font-size: 3rem;
	}
`;

const Legend = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 0.3rem;
	margin: 0 5%;
	line-height: 1;
	font-size: 0.9rem;
`;

const Option = styled.span`
	color: #485156;
	font-family: 'Helvetica Now Display W01 ${({ weight }) => weight || 'Rg'}';
	width: ${({ width }) => width}%;
	text-align: center;
	word-wrap: break-word;
	padding: 0 0.3rem;
	hyphens: auto;

	/* single line options
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden; */
`;

export default class BarChart extends Component {
	constructor(props) {
		super(props);

		this.canvasRef = createRef();
		this.legendRef = createRef();

		this.state = {
			items: [],
			isActive: false,
		};

		this.ctx = null;

		this.raf = null;
		this.animationStart = 0;
		this.redrawTimeout = null;
	}

	componentDidMount() {
		this.ctx = this.canvasRef.current.getContext('2d');

		this.calculateBars();

		if (this.props.isActive) this.startAnimation();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.width !== this.props.width) {
			clearTimeout(this.redrawTimeout);
			this.calculateBars();

			if (this.props.isActive && this.state.isActive) {
				this.redrawTimeout = setTimeout(() => this.startAnimation(), 500);
				return;
			}
		}
		if (!prevProps.isActive && this.props.isActive) {
			this.startAnimation();
		} else if (prevProps.isActive && !this.props.isActive && this.state.isActive) {
			this.setState({ isActive: false });
		}
	}

	calculateBars() {
		const { options, scale, offset, width } = this.props;

		const { height: legendHeight } = this.legendRef.current.getBoundingClientRect();

		const height =
			width / this.props.ratio - Math.min(width / this.props.ratio / 2, legendHeight);

		this.canvasRef.current.setAttribute('width', width);
		this.canvasRef.current.setAttribute('height', height);

		const [offsetX, offsetY] = offset.map((_offset, index) =>
			index === 0 ? width * _offset : height * _offset,
		);

		// the max height a bar can get (it is always the biggest percentage)
		const drawableHeight = height * scale;

		// the width of each section, the bar and the padding (section => padding + bar + padding)
		const sectionWidth = (width * scale) / options.length;
		const barWidth = ((sectionWidth * 2) / 3) * scale;
		const padding = sectionWidth - barWidth;

		// the maximum of all percentages (for normalizing the percentage values)
		const maxPercentage = Math.max(...options.map(({ percentage }) => percentage));

		const items = options.map(({ percentage }, index) => {
			const barHeight = drawableHeight - drawableHeight * (1 - percentage / maxPercentage);

			const x = offsetX + sectionWidth * index + padding / 2;
			const y = offsetY + height;

			return {
				percentage: {
					x: offsetX + sectionWidth * index,
					y: -barHeight,
				},
				bar: {
					x,
					y,
					width: barWidth,
					height: barHeight,
					color: COLORS[index % COLORS.length],
				},
			};
		});

		this.setState({
			width,
			height,
			items,
		});
	}

	startAnimation() {
		setTimeout(() => {
			this.animationStart = Date.now();
			this.animate();

			this.setState({ isActive: true });
		}, 250);
	}

	animate() {
		const { width, height } = this.state;
		let progress = (Date.now() - this.animationStart) / this.props.animationDuration;

		if (progress >= 1) {
			progress = 1;
		}

		// clear the canvas
		this.ctx.clearRect(0, 0, width, height);

		// draw the bottom line
		this.ctx.fillStyle = 'rgba(0,0,0,0.2)';
		this.ctx.fillRect(0, height, width, height * -0.05);

		const ease = easeFn(progress);

		this.state.items.forEach(({ bar }) => {
			this.drawBar(bar.x, bar.y, bar.width, bar.height * ease, bar.color);
		});

		if (progress < 1) this.raf = window.requestAnimationFrame(() => this.animate(), 100);
	}

	drawBar(x, y, width, height, color) {
		this.ctx.fillStyle = color;
		this.ctx.fillRect(x, y, width, -height);

		this.ctx.fillStyle = 'rgba(0,0,0,0.2)';
		this.ctx.fillRect(x + width * 0.85, y, width * 0.15, -height);

		// reset the fillStyle
		this.ctx.fillStyle = '#000';
	}

	render() {
		const { items, isActive } = this.state;
		const { options, animationDuration, useGivenAnswers } = this.props;

		/* eslint-disable react/no-array-index-key */
		return (
			<Wrapper className={isActive ? 'active' : ''} animationDuration={animationDuration}>
				<Container>
					<canvas ref={this.canvasRef} />
					{items.map(({ percentage: { x, y } }, index) => (
						<Percentage
							key={index}
							x={x}
							y={y}
							width={100 / options.length}
							animationDuration={animationDuration}
							isActive={isActive}
						>
							{useGivenAnswers
								? options[index].givenAnswers
								: `${options[index].percentage}%`}
						</Percentage>
					))}
				</Container>
				<Legend ref={this.legendRef}>
					{options.map(({ value }, index) => (
						<Option key={value} color={COLORS[index]} width={100 / options.length}>
							{value}
						</Option>
					))}
				</Legend>
			</Wrapper>
		);
	}
}

BarChart.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			percentage: PropTypes.number,
			givenAnswers: PropTypes.number,
		}),
	),
	scale: PropTypes.number,
	offset: PropTypes.arrayOf(PropTypes.number),
	ratio: PropTypes.number,
	width: PropTypes.number,
	animationDuration: PropTypes.number,
	isActive: PropTypes.bool,
	useGivenAnswers: PropTypes.bool,
};

BarChart.defaultProps = {
	scale: 0.9,
	offset: [0.05, 0],
	ratio: 16 / 10,
	width: 100,
	animationDuration: 500,
	useGivenAnswers: false,
};
