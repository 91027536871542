import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

import styled from '@emotion/styled';
import SurveyContext from '../contexts/SurveyContext';

import { Button, MultiSelectQuestion, SingleSelectQuestion, SmallText } from '../components';
import { post, clearCache, get } from '../utils/request';
import { sentryIds } from '../utils/errors';

const QUESTION_TYPES_MAP = {
	singleSelect: SingleSelectQuestion,
	boolean: SingleSelectQuestion,
	multiSelect: MultiSelectQuestion,
};

const QuestionContainer = styled.div`
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
`;

export default function SurveyQuestion() {
	const { t } = useTranslation();
	const { push } = useHistory();

	const { hash, token, increaseCacheCount } = useContext(SurveyContext);
	const { locale, surveyId } = useParams();

	const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
	const [survey, setSurvey] = useState(null);
	const [current, setCurrent] = useState(0);
	const [answers, setAnswers] = useState([]);
	const [errorCode, setErrorCode] = useState(null);

	const changeHandler = (value) =>
		setAnswers(
			answers.map((answer, index) => (index === current ? { ...answer, value } : answer)),
		);

	useEffect(() => {
		// load the right survey
		get(`/${locale}/surveys/${surveyId}.json`)
			.then(({ data, success }) => {
				if (success && data) {
					setSurvey(data);
					setAnswers(
						data.questions.map(({ id }) => ({
							survey: surveyId,
							question: id,
							value: null,
						})),
					);
				} else {
					push(`/${locale}/error/1588141963941`);
				}
			})
			.catch(() => {
				push(`/${locale}/error/1588141963941`);
			});
	}, [locale, surveyId, push]);

	if (!hash || !token) return <Redirect to={`/${locale}`} />;

	// wait until everything is loaded
	if (!survey || !answers.length) return <></>;

	const question = survey.questions[current];
	const QuestionComponent = QUESTION_TYPES_MAP[question.type];

	const sendResults = () => {
		post(`/${locale}/surveys/${survey.id}.json`, {
			participant: { token, hash },
			answers,
		})
			.then((response) => {
				if (response.success === true) {
					clearCache(
						`/${locale}/surveys/${survey.id}/check.json`,
						{
							hash,
							token,
						},
						'GET',
					);
					clearCache(`/${locale}.json`);
					clearCache(`/${locale}/surveys/${survey.id}.json`);
					increaseCacheCount();
					push(`/${locale}/surveys/${survey.id}/end`);
				} else {
					setErrorCode(response.error.code);

					// Capture exception for defined error codes
					if (sentryIds.indexOf(response.error.code) > -1) {
						Sentry.withScope((scope) => {
							scope.setExtras(response.error);

							// Capture exception
							Sentry.captureException(new Error(response.error.message));
						});
					}
				}
			})
			.catch(() => {
				push(`/${locale}/error/1588141963941`);
			});
	};

	return (
		<>
			<QuestionContainer>
				<SmallText center margin={[0.5, 0]}>
					{t('surveys.questions.position', {
						current: current + 1,
						max: survey.questions.length,
					})}
				</SmallText>

				<QuestionComponent question={question} onChange={changeHandler} />
			</QuestionContainer>

			{current === survey.questions.length - 1 ? (
				<>
					<Button
						disabled={sendButtonDisabled}
						onClick={() => {
							setSendButtonDisabled(true);
							sendResults();
						}}
					>
						{t('surveys.questions.lastButton')}
					</Button>
					{errorCode !== null && (
						<SmallText color="#fff">{t(`api.${errorCode}`)}</SmallText>
					)}
				</>
			) : (
				<Button
					disabled={answers[current].value === null}
					onClick={() => setCurrent(current + 1)}
				>
					{t('surveys.questions.nextButton')}
				</Button>
			)}
		</>
	);
}
