import React, { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ResizeObserver from 'resize-observer-polyfill';

import { LargeText, Result } from './index';
import { questionPropTypes } from '../utils/prop-types';
import { Text } from './Text';
import Translate from './Translate';

const SliderWrapper = styled.div`
	margin-bottom: 2rem;
	width: 100%;
	max-width: 100%;
	overflow: hidden;
`;

const SliderViewport = styled.div`
	width: ${({ slides }) => 100 * slides}%;
	display: flex;
	transform: translateX(-${({ slides, position }) => (100 / slides) * position}%);
	transition: transform 500ms ease-in-out;

	> div {
		width: ${({ slides }) => 100 / slides}%;
	}
`;

const Slide = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: space-between;
`;

const SliderNavigation = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem 0;
`;

const SliderJumper = styled.button`
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 50%;
	padding: 0;
	outline: none;
	border: none;

	background-color: ${({ active }) => (active ? '#003584' : '#575757')};
	opacity: ${({ active }) => (active ? 0.5 : 0.2)};

	cursor: pointer;

	margin-right: 10px;
`;

const SliderTimerWrapper = styled.div`
	line-height: 0;

	width: 1.5rem;
	height: 1.5rem;

	svg {
		width: 100%;
		height: 100%;
	}

	circle {
		animation: tickTock linear ${({ autoTimer }) => autoTimer}ms;
		animation-iteration-count: infinite;
		animation-play-state: ${({ animationPlayState }) => animationPlayState};
	}

	@keyframes tickTock {
		from {
			/* the circumference of the circle (2 * Pi * 8) (8? => r + strokeWidth/2 = 8) */
			stroke-dashoffset: 25.132741228718345;
		}
		to {
			stroke-dashoffset: 0;
		}
	}
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 1rem;
	background-color: #fff;
	font-size: 20px;
	line-height: 28px;
`;

const SliderTimer = () => (
	<svg viewBox="0 0 16 16">
		<circle
			cx="8"
			cy="8"
			r="4"
			strokeWidth="8"
			strokeDasharray="25.132741228718345"
			stroke="#575757"
			opacity="0.5"
			transform="rotate(-90, 8, 8)"
			fill="none"
		/>
	</svg>
);

export default class Slider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sliderPosition: 0,
			resetAnimation: false,
			width: 0,
		};

		this.ref = createRef();

		this.timer = null;
		this.resizeObserver = null;
	}

	componentDidMount() {
		this.slide();

		const { width } = this.ref.current.getBoundingClientRect();
		this.setState({ width });

		let observerStarted = false;

		this.resizeObserver = new ResizeObserver(([{ contentRect }]) => {
			// Only start observing after the first run
			if (observerStarted) {
				if (contentRect.width !== this.state.width) {
					this.resizeObserver.disconnect();
					this.setState({ width: contentRect.width });

					observerStarted = false;
					this.resizeObserver.observe(this.ref.current);
				}
			}

			observerStarted = true;
		});

		this.resizeObserver.observe(this.ref.current);
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
		if (this.resizeObserver) this.resizeObserver.disconnect();
	}

	slide() {
		const { autoTimer, questions } = this.props;

		this.timer = setTimeout(() => {
			this.setState(({ sliderPosition }) => ({
				sliderPosition: (sliderPosition + 1) % questions.length,
			}));
			this.slide();
		}, autoTimer);
	}

	render() {
		const { participants, questions, autoTimer } = this.props;
		const { sliderPosition, resetAnimation, width } = this.state;

		return (
			<SliderWrapper ref={this.ref}>
				<SliderViewport slides={questions.length} position={sliderPosition}>
					{questions.map((question, index) => (
						<Slide key={question.id}>
							<LargeText color="#485156">{question.title}</LargeText>
							<Text color="#485156" margin={[0, 0, 0.5, 0]} weight="Light">
								{participants} <Translate id="surveys.participants" />
							</Text>
							<Result
								outputType={question.outputType}
								options={question.options}
								width={width}
								isActive={sliderPosition === index}
								useGivenAnswers={question.type === 'multiSelect'}
							/>
						</Slide>
					))}
				</SliderViewport>
				<SliderNavigation>
					{questions.map(({ id }, index) => (
						<SliderJumper
							key={id}
							type="button"
							active={sliderPosition === index}
							onClick={() => {
								this.setState({ resetAnimation: true });
								// add a little delay so the svg is really hard re-rendered and the animation resetted
								setTimeout(() => {
									clearTimeout(this.timer);
									this.setState({ sliderPosition: index, resetAnimation: false });
									this.slide();
								}, 16);
							}}
						/>
					))}
					<SliderTimerWrapper autoTimer={autoTimer}>
						{!resetAnimation && <SliderTimer />}
					</SliderTimerWrapper>
				</SliderNavigation>
				<SliderViewport slides={questions.length} position={sliderPosition}>
					{questions.map((question) => (
						<Slide key={question.id}>
							<TextContainer>
								<LargeText color="#485156">{question.question}</LargeText>
								{question.type === 'multiSelect' && (
									<Text color="#999" margin={[0.5, 0, 0, 0]}>
										(<Translate id="surveys.multipleChoice" />)
									</Text>
								)}
							</TextContainer>
						</Slide>
					))}
				</SliderViewport>
			</SliderWrapper>
		);
	}
}

Slider.propTypes = {
	participants: PropTypes.number.isRequired,
	questions: PropTypes.arrayOf(questionPropTypes).isRequired,
	autoTimer: PropTypes.number,
};

Slider.defaultProps = {
	autoTimer: 15000,
};
